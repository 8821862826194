import React from 'react';
import {Button} from './Button';

export const Br = () => {
    return <br/>
}


export const Zu = ({yin, zi}) => {
    return <span style={{
    padding: "0 5",
    textAlign: "center",
    margin: 3,
    marginBottom: 5,
    display: "block",
    float: "left",
    }}>{yin}<br />{zi}</span>
}

export const Text = ({children}) => {
    return <div style={{
        padding: "3 0",
        display: "block",
        float: "left"
    }}>{children}</div>
}

export const PrevNext = ({prev, next}) => {
    return (
        <div style={{
            display: "block",
            float: "left"
        }}>
            <Button kind='primary' path={prev}>上一页</Button>
            <Button kind='dark' path={next}>下一页</Button>
        </div>
    )
}

export const Prev = ({path}) => {
    return (
        <div style={{
            display: "block",
            float: "left"
        }}>
            <Button kind='primary' path={path}>上一页</Button>
        </div>
    )
}

export const Next = ({path}) => {
    return (
        <div style={{
            display: "block",
            float: "left"
        }}>
            <Button kind='dark' path={path}>下一页</Button>
        </div>
    )
}