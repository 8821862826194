import React from 'react'
import t from 'prop-types'
import { Link } from 'gatsby'

const kinds = {
  primary: '#5352ED',
  secondary: '#2ED573',
  cancel: '#FF4757',
  dark: '#4E4C67',
  gray: '#E2DCDE'
}

export const Button = ({ children, kind, path }) => {
  // We use the kind prop to determine the button's class
  return <Link to={path} style={{
    padding: 10,
    margin: 5,
    borderRadius: 3,
    color: 'white',
    fontSize: 15,
    background: kinds[kind],
  }}>{children}</Link>
}

Button.propTypes = {
  /**
   * This is a pretty good description for this prop.
   */
  kind: t.oneOf(['primary', 'secondary', 'cancel', 'dark', 'gray']),
}

Button.defaultProps = {
  kind: 'primary',
  path: '/'
}