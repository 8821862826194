// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---readme-md": () => import("./../../../../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---src-components-alert-mdx": () => import("./../../../../src/components/Alert.mdx" /* webpackChunkName: "component---src-components-alert-mdx" */),
  "component---src-components-button-mdx": () => import("./../../../../src/components/Button.mdx" /* webpackChunkName: "component---src-components-button-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dizangjing-dz-v-1-mdx": () => import("./../../../../src/pages/dizangjing/dz-v1.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-1-mdx" */),
  "component---src-pages-dizangjing-dz-v-10-mdx": () => import("./../../../../src/pages/dizangjing/dz-v10.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-10-mdx" */),
  "component---src-pages-dizangjing-dz-v-11-mdx": () => import("./../../../../src/pages/dizangjing/dz-v11.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-11-mdx" */),
  "component---src-pages-dizangjing-dz-v-12-mdx": () => import("./../../../../src/pages/dizangjing/dz-v12.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-12-mdx" */),
  "component---src-pages-dizangjing-dz-v-13-mdx": () => import("./../../../../src/pages/dizangjing/dz-v13.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-13-mdx" */),
  "component---src-pages-dizangjing-dz-v-2-mdx": () => import("./../../../../src/pages/dizangjing/dz-v2.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-2-mdx" */),
  "component---src-pages-dizangjing-dz-v-3-mdx": () => import("./../../../../src/pages/dizangjing/dz-v3.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-3-mdx" */),
  "component---src-pages-dizangjing-dz-v-4-mdx": () => import("./../../../../src/pages/dizangjing/dz-v4.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-4-mdx" */),
  "component---src-pages-dizangjing-dz-v-5-mdx": () => import("./../../../../src/pages/dizangjing/dz-v5.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-5-mdx" */),
  "component---src-pages-dizangjing-dz-v-6-mdx": () => import("./../../../../src/pages/dizangjing/dz-v6.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-6-mdx" */),
  "component---src-pages-dizangjing-dz-v-7-mdx": () => import("./../../../../src/pages/dizangjing/dz-v7.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-7-mdx" */),
  "component---src-pages-dizangjing-dz-v-8-mdx": () => import("./../../../../src/pages/dizangjing/dz-v8.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-8-mdx" */),
  "component---src-pages-dizangjing-dz-v-9-mdx": () => import("./../../../../src/pages/dizangjing/dz-v9.mdx" /* webpackChunkName: "component---src-pages-dizangjing-dz-v-9-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-jingangjing-jgj-mdx": () => import("./../../../../src/pages/jingangjing/jgj.mdx" /* webpackChunkName: "component---src-pages-jingangjing-jgj-mdx" */),
  "component---src-pages-jingangjing-lxz-mdx": () => import("./../../../../src/pages/jingangjing/lxz.mdx" /* webpackChunkName: "component---src-pages-jingangjing-lxz-mdx" */),
  "component---src-pages-xinjing-xj-mdx": () => import("./../../../../src/pages/xinjing/xj.mdx" /* webpackChunkName: "component---src-pages-xinjing-xj-mdx" */)
}

